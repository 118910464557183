<template>
  <div class="page-item-wrap sharingImg-wrap">
    <div id="sharingImg-con">
      <img
        src="~assets/image/main/sharingImg.png"
        id="sharingMaps"
        usemap="#sharingMaps"
        alt="공유하기"
      />
      <map name="sharingMaps">
        <area
          alt="뒤로가기"
          title="뒤로가기"
          shape="rect"
          coords="25,20,65,50"
          style="outline: none; cursor: pointer"
          @click="goBack()"
          target="_self"
        />
        <area
          alt="메뉴"
          title="메뉴"
          shape="rect"
          coords="330,20,365,50"
          style="outline: none"
          @click="openMobileMenu()"
          target="_self"
        />
        <area
          alt="문자공유하기"
          title="문자공유하기"
          shape="rect"
          coords="35,490,360,540"
          style="outline: none"
          @click="Sharing('SMS')"
          target="_blank"
        />
        <area
          alt="밴드공유하기"
          title="밴드공유하기"
          shape="rect"
          coords="35,565,360,610"
          style="outline: none"
          @click="Sharing('BAND')"
          target="_blank"
        />
        <area
          alt="카카오톡공유하기"
          title="카카오톡공유하기"
          shape="rect"
          coords="35,635,360,680"
          style="outline: none"
          @click="Sharing('KAKAO')"
          target="_blank"
        />
      </map>
    </div>
    <!--모바일 메뉴-->
    <mobile-menu :visible.sync="mobileMenuVisible"></mobile-menu>
  </div>
</template>

<script>
import MobileMenu from '../components/header/partials/MobileMenu'
import { getBasicUserInfo } from 'Api/modules'
export default {
  props: {},
  components: {
    MobileMenu,
  },
  data: () => ({
    mobileMenuVisible: false,
    //SNS공유 data
    sms: 'sms:?body=',
    SharingPhrase: '님이 호구153을 공유하였습니다.',
    SharingContent:
      '구직자에겐 좋은 직장을, 구인자에겐 좋은 사람을,' +
      '\n' +
      '호텔 구인구직 매칭 플랫폼 호구153',
    joinUrl: 'https://hogoo153.com/join?recommenderPhone=',
    iosUrl: 'https://url.kr/7ukosm',
    androidUrl: 'https://url.kr/dj6zeq',
    //SNS공유 data -----
  }),
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    openMobileMenu() {
      this.mobileMenuVisible = true
    },
    Sharing(sns) {
      var t = '?'
      var varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase(),
      )
      if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        // alert('아이폰')
        t = '&'
      }
      var smstext =
        'sms:' +
        t +
        'body=' +
        this.account.userNm +
        this.SharingPhrase +
        '\n' +
        '\n' +
        this.SharingContent +
        '\n' +
        '\n' +
        '회원가입 : ' +
        this.joinUrl +
        this.phone +
        '\n' +
        '\n' +
        '앱다운로드' +
        '\n' +
        '\n' +
        '안드로이드 : ' +
        this.androidUrl +
        '\n' +
        'IOS : ' +
        this.iosUrl
      var SharingText = smstext.replace(/(\n|\r\n)/g, '%0a')
      // var sendUrl = encodeURIComponent(this.sendUrl)
      // var title = this.title
      // var content = this.jejuSnsContentsTerms
      if (sns == 'SMS') {
        if (mobile === false) {
          this.$toasted.error('문자 공유하기는 모바일 기기에서만 가능합니다.')
          return false
        } else {
          location.href = SharingText
        }
      } else if (sns == 'BAND') {
        SharingText = SharingText.replace('sms:?body=', '')
        window.open('https://band.us/plugin/share?body=' + SharingText)
      } else if (sns == 'KAKAO') {
        var SharingUrl = this.joinUrl + this.phone
        var enCodUrl = SharingUrl.replace(/(\n|\r\n)/g, '%0a')
        console.log(enCodUrl)
        window.Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: this.account.userNm + this.SharingPhrase,
            description: this.SharingContent,
            imageUrl:
              'https://www.hogoo153.com/img/SharingAppIcon.84519974.png',
            imageWidth: 336,
            imageHeight: 280,
            link: {
              mobileWebUrl:
                'https://www.hogoo153.com/join?recommenderPhone=' + this.phone,
              webUrl:
                'https://www.hogoo153.com/join?recommenderPhone=' + this.phone,
            },
          },
          buttons: [
            {
              title: '회원가입',
              link: {
                mobileWebUrl:
                  'https://www.hogoo153.com/join?recommenderPhone=' +
                  this.phone,
                webUrl:
                  'https://www.hogoo153.com/join?recommenderPhone=' +
                  this.phone,
              },
            },
          ],
        })
      }
    },
    async fetchPersonalInfo() {
      const res = await getBasicUserInfo()
      this.phone = res.phone
    },
  },
  async created() {
    await this.fetchPersonalInfo()
    this.$nextTick(() => {
      window.$('img[usemap]').rwdImageMaps()
    })
  },
}
</script>

<style>
.sharingImg-wrap {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.sharingImg-wrap #sharingImg-con img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}
.sharingImg-wrap #sharingImg-con area {
  cursor: pointer;
}
</style>
